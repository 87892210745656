<template>
    <div class="container-papeis">
        <unicom-linha espacamento>
            <unicom-coluna :sm="12" :md="3" class="secao">
                <Lista
                    :papeis="montarListaPapeis"
                    @click="item => clickPapel(item)"
                    @adicionar-papel="mostrarModal = true"
                />
            </unicom-coluna>
            <unicom-coluna :sm="12" :md="9" class="secao tabela">
                <TabelaUsuarioAplicacao
                    ref="usuarioAplicacao"
                    :papeis="montarListaPapeis"
                    @adicionar-usuario="mostrarUsuarioAplicacao = true"
                    @atualizar="$emit('atualizar')"
                />
            </unicom-coluna>
        </unicom-linha>

        <ModalAplicacao
            v-model="mostrarModal"
            :id="idAplicacao"
            :papeis="montarPapeisModal"
            @fechar="fecharModal()"
            @novo-papel="$emit('atualizar')"
        />

        <ModalUsuarioAplicacao
            v-model="mostrarUsuarioAplicacao"
            :papeis="montarListaPapeis"
            @fechar="mostrarUsuarioAplicacao = false"
            @recarregar="recarregarListagem()"
        />
        <unicom-modal-sucesso
            v-model="mostrarModalSucesso"
            titulo="Usuário adicionado com sucesso."
            @fechar="mostrarModalSucesso = false"
        />
    </div>
</template>

<script>
import Lista from "./Lista";
import TabelaUsuarioAplicacao from "./TabelaUsuarioAplicacao";
import ModalAplicacao from "./ModalAplicacao";
import ModalUsuarioAplicacao from "./ModalUsuarioAplicacao";

export default {
    components: {
        Lista,
        TabelaUsuarioAplicacao,
        ModalAplicacao,
        ModalUsuarioAplicacao
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            note: "Id da aplicação selecionada"
        },
        papeis: {
            type: Array,
            required: false,
            note: "Lista de papéis de uma aplicação"
        }
    },
    data() {
        return {
            idAplicacao: this.id,
            listaPapeis: this.papeis,
            mostrarModal: false,
            mostrarUsuarioAplicacao: false,
            mostrarModalSucesso: false
        };
    },
    computed: {
        montarListaPapeis() {
            let lista = [];
            this.listaPapeis.forEach(papel => {
                lista.push({
                    id: papel.id,
                    nome: papel.nome,
                    quantidade: papel.quantidade,
                    selecionado: false
                });
            });

            return lista;
        },
        montarPapeisModal() {
            let lista = [];

            this.listaPapeis.forEach(papel => {
                lista.push({
                    id: papel.id,
                    nome: papel.nome,
                    adminOnly: papel.adminOnly,
                    descricao: papel.descricao,
                    keyUUID: papel.keyUuid,
                    edicao: false
                });
            });

            return lista;
        }
    },
    methods: {
        clickPapel() {
            //this.$refs.usuarioAplicacao.paginar();
        },
        recarregarListagem() {
            this.mostrarModalSucesso = true
            this.$emit("atualizar")
        },
        fecharModal() {
            this.mostrarModal = false;
            
            this.$emit("atualizar");
        }
    },
    watch: {
        
    }
};
</script>

<style lang="scss" scoped>
.container-papeis {
    padding-bottom: 30px;

    .secao {
        height: 478px;
        border-radius: 5px;
        border: 1px solid #dee2e6;

        &.tabela {
            padding-top: 22px;
        }

        overflow: auto;
    }
}
</style>
