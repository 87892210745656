import Axios from "axios";

var AplicacoesServiceMixin = {
    methods: {
        getAplicacoes() {
            return Axios.get(this.CriarUrl({ resource: "api/aplicacoes" }));
        },

        postNovoClientId(id) {
            return Axios.post(
                this.CriarUrl({
                    resource: `api/aplicacoes/${id}/renew-client-id`
                }),
                {},
                { withCredentials: true }
            );
        },

        postNovoClientSecret(id) {
            return Axios.post(
                this.CriarUrl({
                    resource: `api/aplicacoes/${id}/renew-client-secret`
                }),
                {},
                { withCredentials: true }
            );
        },

        getContasServico(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/aplicacoes/filtra",
                    params: args
                })
            );
        }
    }
};

export default AplicacoesServiceMixin;
