import { render, staticRenderFns } from "./TabelaAplicacao.vue?vue&type=template&id=24c1cfc0&scoped=true&"
import script from "./TabelaAplicacao.vue?vue&type=script&lang=js&"
export * from "./TabelaAplicacao.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c1cfc0",
  null
  
)

export default component.exports