<template>
    <div>
        <unicom-tabela
            :carregamento="loading"
            :icones="iconesUsadosTabela"
            :itensPorPagina="['5', '10', '15', '20']"
            :ordem="ordem"
            :pagina="pagina"
            :qtPaginas="qtPaginas"
            :qtResultados="qtResultados"
            :rows="rows"
            :cabecalho="cabecalho"
            :textoExibindo="'aplicações'"
            @troca-elemento-pagina="item => trocaElementoPagina(item)"
            @ordena="item => ordenar(item)"
            @troca-pagina="item => trocaPagina(item)"
            @acao="item => acao(item)"
        >
            <div slot="header">
                <unicom-linha :espacamento="true" :justificarItem="'end'">
                    <unicom-coluna :md="9">
                        <unicom-barra-pesquisa
                            :value="campoPesquisa"
                            :placeholder="'Buscar Nome ou URL da aplicaçao...'"
                            @pesquisar="valor => pesquisarAplicacao(valor)"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="3">
                        <unicom-botao
                            :bloco="true"
                            class="fonte-secundaria texto"
                            @click="$emit('cadastrar-aplicacao')"
                        >
                            CADASTRAR APLICAÇÃO
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-tabela>
        <unicom-modal-loading v-model="carregamentoOverlay" />
    </div>
</template>

<script>
import Aplicacao from "@/services/gerenciarAplicacoes/Aplicacao";

export default {
    data() {
        return {
            campoPesquisa: "",
            ordem: "asc",
            campoOrdenado: "",
            itensPorPagina: 5,
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            nome: "",
            url: "",
            iconesUsadosTabela: [
                {
                    nome: "edit",
                    tooltipNome: "Editar"
                }
            ],
            cabecalho: [
                {
                    texto: "Nome da aplicação",
                    icone: "maximize-2",
                    largura: "262px"
                },
                {
                    texto: "URL de acesso",
                    largura: "307px"
                },
                {
                    texto: "Descrição",
                    largura: "301px"
                },
                {
                    texto: "Status",
                    icone: "maximize-2"
                },
                {
                    texto: "Ações"
                }
            ],
            rows: [],
            listaAplicacoes: [],
            loading: false,
            carregamentoOverlay: false
        };
    },
    async beforeMount() {
        this.getListaAplicacoes("");
    },
    methods: {
        async getListaAplicacoes(args) {
            this.loading = true;
            let aplicacaoGet = new Aplicacao(this.$appConfig.config.API);
            try {
                let listaAplicacoes = await aplicacaoGet.getListaAplicacoes(args);
                listaAplicacoes = listaAplicacoes.data;
                this.montarTabela(listaAplicacoes);
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.error(err.response.data);
            }
        },
        async setAplicacao(aplicacao) {
            this.carregamentoOverlay = true;
            let aplicacaoSet = new Aplicacao(this.$appConfig.config.API);
            try {
                let aplicacaoset = await aplicacaoSet.postAplicacoes(aplicacao);
                this.carregamentoOverlay = false;
                this.$emit("put-aplicacao-sucesso", aplicacaoset.data);
                await this.getListaAplicacoes("");
            } catch (err) {
                this.carregamentoOverlay = false;
                console.error(err);
            }
        },
        montarTabela(objeto) {
            this.listaAplicacoes = [];
            this.rows = [];

            this.listaAplicacoes = objeto.conteudo;
            this.listaAplicacoes.forEach(item => {
                this.rows.push({
                    nome: {
                        texto: item.nome,
                        qtdMaxCaracteres: 30,
                        tooltipLocalizacao: "top-left"
                    },
                    url: item.url,
                    descricao: item.descricao,
                    status: {
                        tooltipStatus: item.ativa
                            ? "Aplicação vísivel para usuários"
                            : "Aplicação invísivel para usuários",
                        tipo: "padrao",
                        valor: item.ativa
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },
        trocaElementoPagina(item) {
            this.pagina = 1;
            this.itensPorPagina = item;
            this.paginar();
        },
        ordenar(item) {
            switch (item.index) {
                case 0:
                    this.campoOrdenado = "nome";
                    break;
                case 3:
                    this.campoOrdenado = "ativa";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },
        trocaPagina(item) {
            this.pagina = item.pagina;
            this.itensPorPagina = item.itensPorPagina;
            this.paginar();
        },
        acao(item) {
            this.$emit("editar", this.listaAplicacoes[item.id]);
        },
        paginar() {
            this.getListaAplicacoes(this.getArgs());
        },
        pesquisarAplicacao(valor) {
            const result = this.buscaAplicacoes(valor);

            this.nome = "";
            this.url = "";

            if (result) this[result] = valor;

            this.pagina = 1;
            this.paginar();
        },
        getArgs() {
            let args = this.$helpers.geradorParams(
                {
                    name: "direcaoOrdenacao",
                    value: this.ordem
                },
                {
                    name: "ordenacao",
                    value: this.campoOrdenado
                },
                {
                    name: "pagina",
                    value: this.pagina
                },
                {
                    name: "tamanhoPagina",
                    value: this.itensPorPagina
                },
                {
                    name: "nome",
                    value: this.nome
                },
                {
                    name: "url",
                    value: this.url
                }
            );
            return args;
        }
    }
};
</script>

<style lang="scss" scoped></style>
