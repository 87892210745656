import axios from "axios";

export default class Papel {
    constructor(url) {
        this.url = `${url}`;
    }

    patchNovoPapel(idAplicacao, data) {
        return new Promise(async (resolve, reject) => {
            let optionsNovoPapel = {
                method: "patch",
                url: `${this.url}/api/papeisAplicacao/aplicacoes/${idAplicacao}`,
                data,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("Authorization")}`
                }
            };

            try {
                let responseNovoPapel = await axios(optionsNovoPapel);
                resolve(responseNovoPapel);
            } catch (err) {
                reject(err);
            }
        });
    }

    putEditarPapel(idPapel, data) {
        return new Promise(async (resolve, reject) => {
            let optionsEditarPapel = {
                method: "put",
                url: `${this.url}/api/papeisAplicacao/${idPapel}`,
                data,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("Authorization")}`
                },
                withCredentials: true
            };

            try {
                let responseEditarPapel = await axios(optionsEditarPapel);
                resolve(responseEditarPapel);
            } catch (err) {
                reject(err);
            }
        });
    }

    deletePapel(idPapel, idAplicacao) {
        return new Promise(async (resolve, reject) => {
            let optionsDeletePapel = {
                method: "delete",
                url: `${this.url}/api/papeisAplicacao/${idPapel}/aplicacoes/${idAplicacao}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("Authorization")}`
                },
                withCredentials: true
            };

            try {
                let responseDeletePapel = await axios(optionsDeletePapel);
                resolve(responseDeletePapel);
            } catch (err) {
                reject(err);
            }
        });
    }
}
