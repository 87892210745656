<template>
    <unicom-modal v-model="mostrar" :largura="500" fechar @input="valor => fecharModal(valor)">
        <div slot="header">
            <h3 class="fonte-primaria titulo negrito">{{ titulo }}</h3>
        </div>
        <div slot="body" class="fonte-secundaria medio modal-corpo">
            <slot name="conteudo"></slot>
        </div>
        <div slot="footer">
            <unicom-linha :espacamento="true" :justificarItem="'center'">
                <unicom-coluna :md="4">
                    <unicom-botao class="fonte-secundaria texto" :bloco="true" @click="fecharModal(false)"
                        >FECHAR</unicom-botao
                    >
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        titulo: {
            type: String,
            required: false,
            default: "",
            note: "Título da modal de sucesso"
        }
    },
    data() {
        return {
            mostrar: this.value
        };
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    text-align: center;
}

p {
    line-height: 25px;
}

.modal-corpo {
    margin: 24px 0;
    text-align: center;
}
</style>
