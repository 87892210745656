import Axios from "axios";

var UsuarioAplicacaoServiceMixin = {
    methods: {
        getUsuarioInclusoPapel(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/usuario-unimed/obtem-todos-por-papel",
                    params: args
                }),
                { withCredentials: true }
            );
        },
        deleteExcluirTodosPapel(args) {
            return Axios.delete(
                this.CriarUrl({
                    resource: "api/usuario-unimed/remover-todos-por-papel",
                    params: args
                }),
                { withCredentials: true }
            );
        },
        getUsuarioExclusoPapel(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/usuario-unimed/obtem-todos-not-in-papel",
                    params: args
                }),
                { withCredentials: true }
            );
        },
        postAdicionarTodosExclusoPapel(args) {
            return Axios.post(
                this.CriarUrl({
                    resource: "api/usuario-unimed/adicionar-todos-not-in-papel",
                    params: args
                }),
                {},
                { withCredentials: true }
            );
        },
        postAdicionarUsuarioPapel(data) {
            return Axios.post(
                this.CriarUrl({
                    resource: "api/usuario-unimed/adicionar-papel"
                }),
                data,
                { withCredentials: true }
            );
        },
        deleteUsuarioPapel(data) {
            return Axios.post(
                this.CriarUrl({
                    resource: "api/usuario-unimed/remover-papel"
                }),
                data,
                { withCredentials: true }
            );
        }
    }
};

export default UsuarioAplicacaoServiceMixin;
