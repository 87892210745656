<template>
    <div class="gerenciar-aplicacoes-container">
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <h2 class="fonte-primaria negrito titulo">{{ titulo }}</h2>
                <small class="fonte-secundaria texto">Início > <span class="negrito">Gerenciar Aplicações</span></small>
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <div class="conteudo-principal-container">
                    <ListarAplicacao
                        v-if="!editar"
                        @editar-aplicacao="aplicacao => editarAplicacao(aplicacao)"
                        @criada-aplicacao-atribuir-papel="aplicacao => atribuirAplicacao(aplicacao)"
                    />

                    <MinhaAplicacao
                        v-else
                        ref="minhaAplicacao"
                        :aplicacaoSelecionada="aplicacaoSelecionada"
                        :trocarParaPapeis="trocarParaPapeis"
                        @fechar="fecharEdicao()"
                        @atualizar-aplicacao="id => getAplicacaoAtualizada(id, 0)"
                        @atualizar-client-id="id => getAplicacaoAtualizada(id, 1)"
                        @atualizar-client-secret="id => getAplicacaoAtualizada(id, 2)"
                    />
                </div>
            </unicom-coluna>
        </unicom-linha>
        <unicom-modal-loading :value="loading" />
    </div>
</template>

<script>
import Aplicacao from "@/services/gerenciarAplicacoes/Aplicacao";

import ListarAplicacao from "./shared/ListarAplicacao/ListarAplicacao";
import MinhaAplicacao from "./shared/MinhaAplicacao/MinhaAplicacao";

export default {
    components: {
        ListarAplicacao,
        MinhaAplicacao
    },
    data() {
        return {
            editar: false,
            aplicacaoSelecionada: {},
            trocarParaPapeis: false,
            loading: false
        };
    },
    computed: {
        titulo() {
            return this.editar ? "EDITAR APLICAÇÃO" : "GERENCIAR APLICAÇÕES";
        }
    },
    methods: {
        editarAplicacao(aplicacao) {
            this.aplicacaoSelecionada = aplicacao;
            if(!aplicacao.quantidadeUnimed){
                this.aplicacaoSelecionada.quantidadeUnimed = null                 
            }
            this.editar = true;
        },
        fecharEdicao() {
            this.trocarParaPapeis = false;
            this.editar = false;
        },
        atribuirAplicacao(aplicacao) {
            this.trocarParaPapeis = true;
            this.editarAplicacao(aplicacao);
        },
        async getAplicacaoAtualizada(id, atualizar) {
            this.loading = true;
            let aplicacaoAtualizada = new Aplicacao(this.$appConfig.config.API);

            try {
                let aplicacao = await aplicacaoAtualizada.getAplicacao(id);
                aplicacao = aplicacao.data;
                this.loading = false;
                if (atualizar === 0) {
                    this.aplicacaoSelecionada = aplicacao;
                } else if (atualizar === 1) {
                    this.$refs.minhaAplicacao.atualizarClientId(aplicacao);
                } else if (atualizar === 2) {
                    this.$refs.minhaAplicacao.atualizarClientSecret(aplicacao);
                }
            } catch (err) {
                this.loading = false;
                console.error(err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.gerenciar-aplicacoes-container {
    margin: 35px;
    box-sizing: border-box;
    width: calc(100% - 70px);
    color: #60656c;

    h2 {
        margin-bottom: 8px;
    }

    .conteudo-principal-container {
        margin-top: 24px;
    }
}
</style>
