<template>
    <div>
        <unicom-linha :espacamento="false" class="container-campos">
            <unicom-coluna>
                <h3 class="fonte-primaria subtitulo negrito">DADOS DA APLICAÇÃO</h3>
                <p class="fonte-secundaria texto">
                    Os dados abaixo são mostrados para os usuários em "Minhas Aplicações".
                </p>
                <ValidationObserver ref="dadosAplicacao">
                    <unicom-linha :espacamento="true">
                        <unicom-coluna :sm="11" :md="4">
                            <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto
                                    v-model="aplicacaoEditada.nome"
                                    :titulo="'Nome da aplicação *'"
                                    :placeholder="'Insira o nome'"
                                    :maxCaracteres="'50'"
                                    :apenasAlfanumerico="true"
                                    :mensagemErro="errors[0]"
                                />
                            </validation-provider>
                        </unicom-coluna>
                        <unicom-coluna :sm="11" :md="4">
                            <validation-provider mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto
                                    v-model="aplicacaoEditada.descricao"
                                    :titulo="'Descrição breve'"
                                    :placeholder="'Insira a descrição'"
                                    :maxCaracteres="'62'"
                                    :mensagemErro="errors[0]"
                                    tooltip='Será informada junto com o nome da aplicação em "Minhas aplicações".'
                                />
                            </validation-provider>
                        </unicom-coluna>
                        <unicom-coluna :sm="11" :md="3">
                            <unicom-switch
                                :titulo="'Status da aplicação'"
                                v-model="aplicacaoEditada.ativa"
                                :color="'#000'"
                                :disabled="false"
                                :number="0"
                                :textoVerdadeiro="'visível'"
                                :textoFalso="'invisível'"
                                @input="valor => (aplicacao.status = valor)"
                                tooltip="Enquanto INATIVADA, a aplicação não será listada para usuários."
                            />
                        </unicom-coluna>
                    </unicom-linha>
                </ValidationObserver>
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha :espacamento="false" class="container-campos linha-divisao">
            <unicom-coluna>
                <h3 class="fonte-primaria subtitulo negrito">DADOS DE COMUNICAÇÃO</h3>
                <p class="fonte-secundaria texto">Para o Portal Intercâmbio se comunicar com a ferramenta.</p>
                <ValidationObserver ref="dadosComunicacao">
                    <unicom-linha :espacamento="true">
                        <unicom-coluna :md="4">
                            <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto
                                    v-model="aplicacaoEditada.url"
                                    :titulo="'URL da aplicação *'"
                                    :placeholder="'Insira a URL'"
                                    :maxCaracteres="'300'"
                                    :mensagemErro="errors[0]"
                                    tooltip="Principal URL de comunicação da aplicação."
                                />
                            </validation-provider>
                        </unicom-coluna>
                        <unicom-coluna :md="2">
                            <div class="campo-aplicacao">
                                <unicom-campo-texto
                                    v-model="aplicacaoEditada.clientId"
                                    :titulo="'SSO client id'"
                                    :desativado="true"
                                    tooltip="Dado de acesso para comunicação entre aplicações"
                                />
                            </div>
                            <div class="copiar-aplicacao" @click="copiarDado(aplicacaoEditada.clientId)">
                                <unicom-icone :nome="'copy'" :tamanho="22" />
                            </div>
                        </unicom-coluna>
                        <unicom-coluna :md="2" class="botao-token">
                            <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="$emit('novo-client-id')"
                                >GERAR NOVO</unicom-botao
                            >
                        </unicom-coluna>
                        <unicom-coluna :md="2">
                            <div class="campo-aplicacao">
                                <unicom-campo-texto
                                    v-model="aplicacaoEditada.clientSecret"
                                    :titulo="'SSO client secret'"
                                    :desativado="true"
                                    tooltip="Dado de acesso para comunicação entre aplicações."
                                />
                            </div>
                            <div class="copiar-aplicacao" @click="copiarDado(aplicacaoEditada.clientSecret)">
                                <unicom-icone :nome="'copy'" :tamanho="22" />
                            </div>
                        </unicom-coluna>
                        <unicom-coluna :md="2" class="botao-token">
                            <unicom-botao
                                :bloco="true"
                                class="fonte-secundaria texto"
                                @click="$emit('novo-client-secret')"
                                >GERAR NOVO</unicom-botao
                            >
                        </unicom-coluna>
                    </unicom-linha>
                    <unicom-linha :espacamento="false">
                        <unicom-coluna :sm="11" :md="4">
                            <unicom-checkbox
                                v-model="comunicacao.limitarUsuarios"
                                @input="trocaValorLimitar()"
                                :texto="'Limitar quantidade de usuários por Unimed?'"
                                localizacaoTooltip="top-left"
                                tooltip="Limite máximo de usuários por Unimed. Quando selecionado, esse limite irá valer para todas Unimeds com exceção da Unimed do Brasil."
                            />
                        </unicom-coluna>
                    </unicom-linha>
                    <unicom-linha :espacamento="false">
                        <unicom-coluna :sm="11" :md="1">
                            <validation-provider :rules="validacaoLimitarUsuarios" mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto
                                    v-model="comunicacao.quantidadeUsuarios"
                                    :placeholder="'0'"
                                    :apenasNumero="true"
                                    v-if="comunicacao.limitarUsuarios"
                                    :mensagemErro="errors[0]"
                                />
                            </validation-provider>
                        </unicom-coluna>
                    </unicom-linha>
                </ValidationObserver>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        aplicacaoEditada: {
            type: Object,
            required: false,
            note: "Aplicação a ser editada"
        }
    },
    data() {
        return {
            aplicacao: this.aplicacaoEditada,
            comunicacao: {
                limitarUsuarios: this.aplicacaoEditada.quantidadeUnimed > 0,
                quantidadeUsuarios: this.aplicacaoEditada.quantidadeUnimed
            }
        };
    },
    computed: {
        validacaoLimitarUsuarios(){
            return this.comunicacao.limitarUsuarios ? 'required|numeric|limitadorValidate' : ''
        }
    },
    methods: {
        trocaValorLimitar() {
            this.comunicacao.quantidadeUsuarios = "";
        },
        async validarDados() {
            let validacaoDadosAplicacao = await this.$refs.dadosAplicacao.validate();
            let validacaoDadosComunicacao = await this.$refs.dadosComunicacao.validate();
            return validacaoDadosAplicacao && validacaoDadosComunicacao;
        },
        copiarDado(dado) {
            if (dado) {
                let inputCopiar = document.createElement("input");
                inputCopiar.value = dado;

                document.body.appendChild(inputCopiar);
                inputCopiar.select();

                document.execCommand("copy");
                document.body.removeChild(inputCopiar);
            }
        },

        clientIdGerado(senha) {
            this.aplicacaoEditada.clientId = senha;
        },

        clientSecretGerado(senha) {
            this.aplicacaoEditada.clientSecret = senha;
        }
    },
    watch: {
        aplicacaoEditada() {
            this.aplicacao = this.aplicacaoEditada;
        }
    }
};
</script>

<style lang="scss" scoped>
.container-campos {
    padding-bottom: 30px;

    &.linha-divisao {
        border-top: 1px solid #9e9e9e80;
        padding-top: 40px;
    }

    h3 {
        color: #00995d;
        margin-bottom: 8px;
    }

    p {
        color: #60656c;
        margin-bottom: 26px;
    }

    .botao-token {
        margin-top: 24px;
    }

    .campo-aplicacao {
        float: left;
        box-sizing: border-box;
        width: 85%;
    }

    .copiar-aplicacao {
        float: left;
        margin-top: 40px;
        margin-left: 5%;
        box-sizing: border-box;
        width: 10%;
        color: #ccc;
        cursor: pointer;

        &:hover {
            color: #00995d;
        }
    }
}
</style>
