<template>
    <div>
        <div class="cabecalho-tabela">
            <unicom-linha :espacamento="true" class="linha-papeis-selecionados">
                <unicom-coluna class="fonte-secundaria negrito">
                    <p>
                        <span>Papel selecionado: </span>
                        <small v-for="(papel, index) in listaPapeisSelecionados" :key="index">
                            {{ papel.nome }}{{ index === listaPapeisSelecionados.length - 1 ? "." : "," }}
                        </small>
                    </p>
                </unicom-coluna>
            </unicom-linha>

            <unicom-linha :espacamento="true" :justificarItem="'end'">
                <unicom-coluna :md="3">
                    <unicom-botao
                        :bloco="true"
                        :tipo="'primario'"
                        contornado
                        class="fonte-secundaria texto"
                        @click="alterarFiltro()"
                    >
                        filtro avançado
                    </unicom-botao>
                </unicom-coluna>
                <unicom-coluna :md="6">
                    <unicom-barra-pesquisa
                        :value="campoPesquisa"
                        :placeholder="'Buscar Login ou Nome...'"
                        @pesquisar="valor => pesquisarAplicacao(valor)"
                    />
                </unicom-coluna>
                <unicom-coluna :md="3">
                    <unicom-botao
                        :bloco="true"
                        :desabilitado="habilitarAdicionarUsuario"
                        class="fonte-secundaria texto"
                        @click="$emit('adicionar-usuario')"
                    >
                        ADICIONAR USUÁRIO
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>

            <unicom-linha :espacamento="false" class="linha-filtro-avancado" v-if="mostrarFiltroAvancado">
                <unicom-coluna :md="4" style="margin-left: 16px;margin-right: 16px;">
                    <unicom-autocomplete
                        v-model="filtroUsuario.unimed"
                        :valorData="filtroUsuario.unimed"
                        :items="listaUnimeds"
                        titulo="Unimed"
                        textoDoItem="codigoNome"
                    />
                </unicom-coluna>
                <unicom-coluna :md="4" style="margin-left: 16px;margin-right: 16px;">
                    <unicom-autocomplete
                        v-model="filtroUsuario.tipo"
                        :valorData="filtroUsuario.tipo"
                        :items="listaTiposUsuarios"
                        titulo="Tipo do usuário"
                        textoDoItem="descricao"
                    />
                </unicom-coluna>
                <unicom-coluna :md="3">
                    <unicom-botao :bloco="true" class="fonte-secundaria texto botao" @click="filtrar()">
                        FILTRAR
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
        <div class="fonte-secundaria texto container-selecao" v-if="selecionados.length > 1">
            <unicom-linha espacamento>
                <unicom-coluna md="11">
                    <span class="negrito">{{ quantidadeSelecionados }} usuários</span> desta página serão excluídos.
                    <span class="negrito selecionar-todos" @click="excluirTodos()"
                        >Excluir todos os {{ qtResultados }} usuários.</span
                    >
                </unicom-coluna>
                <unicom-coluna md="1">
                    <div class="icone-excluir" @click="excluirMultiplosUsuarios()">
                        <unicom-icone nome="trash" :tamanho="16" />
                    </div>
                </unicom-coluna>
            </unicom-linha>
        </div>
        <unicom-tabela
            mostrarCheck
            mostrarSelecionados
            :exibirHeader="false"
            :checkCorpoProp="checkCorpoProp"
            :carregamento="loading"
            :icones="iconesUsadosTabela"
            :pagina="pagina"
            :qtPaginas="qtPaginas"
            :qtResultados="qtResultados"
            :rows="rows"
            :cabecalho="cabecalho"
            :textoExibindo="'usuários'"
            @troca-pagina="item => trocaPagina(item)"
            @acao="item => acao(item)"
            @check="valor => checkTabela(valor)"
        >
        </unicom-tabela>
        <unicom-modal-sucesso
            v-model="mostrarModalSucesso"
            :titulo="mensagemModalSucesso"
            @fechar="mostrarModalSucesso = false"
        />
    </div>
</template>

<script>
import UnimedServiceMixin from "@/mixins/services/dominios/UnimedServiceMixin";
import UsuarioAplicacaoServiceMixin from "./services/UsuarioAplicacaoServiceMixin";

export default {
    mixins: [UnimedServiceMixin, UsuarioAplicacaoServiceMixin],
    props: {
        papeis: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            // FILTROS
            campoPesquisa: "",
            mostrarFiltroAvancado: false,
            unimedSelecionada: "",
            tipoSelecionado: "",
            filtroUsuario: {
                unimed: "",
                tipo: ""
            },
            listaUnimeds: [],
            listaTiposUsuarios: [],

            // DADOS
            listaUsuarios: [],

            // TABELA
            loading: false,
            checkCorpoProp: [],
            iconesUsadosTabela: [
                {
                    nome: "trash",
                    tooltipNome: "Excluir"
                }
            ],
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            rows: [],
            cabecalho: [
                {
                    texto: "Nome"
                },
                {
                    texto: "Login"
                },
                {
                    texto: "Unimed"
                },
                {
                    texto: "Perfil"
                },
                {
                    texto: ""
                }
            ],
            selecionados: [],
            mostrarModalSucesso: false,
            mensagemModalSucesso: ""
        };
    },
    computed: {
        listaPapeisSelecionados() {
            let lista = [];
            this.papeis.forEach(item => {
                if (item.selecionado) {
                    lista.push(item);
                }
            });
            return lista;
        },
        habilitarAdicionarUsuario() {
            return this.papeis.find(papel => papel.selecionado) ? false : true;
        },
        quantidadeSelecionados() {
            return this.selecionados.length;
        }
    },
    beforeMount() {
        this.paginar();
        this.getDominios();
    },
    watch: {
        papeis: {
            deep: true,
            immediate: true,
            handler() {
                this.pagina = 1;
                this.paginar();
            }
        }
    },
    methods: {
        // GETS
        getListaUsuarios(args) {
            this.loading = true;
            this.getUsuarioInclusoPapel(args)
                .then(response => {
                    this.montaTabela(response.data);
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                    this.listaUsuarios = [];
                    this.rows = [];
                });
        },
        getDominios() {
            this.getListaUnimeds();
            this.getListaTiposUsuarios();
        },
        getListaUnimeds() {
            this.listaUnimeds = this.getUnimedsList();
        },
        getListaTiposUsuarios() {
            this.getTiposUsuarios()
                .then(response => {
                    this.listaTiposUsuarios = response.data;
                })
                .catch(error => {
                    console.error(error);
                    this.listaTiposUsuarios = [];
                });
        },
        // TABELA
        montaTabela(objeto) {
            this.listaUsuarios = [];
            this.rows = [];

            this.listaUsuarios = objeto.conteudo;
            this.listaUsuarios.forEach(item => {
                this.rows.push({
                    nomeUsuario: {
                        texto: item.usuario.nome,
                        qtdMaxCaracteres: 28
                    },
                    documentoUsuario: {
                        texto: item.usuario.documento.numeroDocumento,
                        qtdMaxCaracteres: 28
                    },
                    unimedUsuario: {
                        texto: item.unimed.nome,
                        qtdMaxCaracteres: 28
                    },
                    papelUsuario: {
                        texto: item.perfil.nome,
                        qtdMaxCaracteres: 28
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },
        trocaPagina(item) {
            this.pagina = item.pagina;
            this.paginar();
        },
        acao(item) {
            this.excluirUnico(item.id);
        },
        excluirUnico(index) {
            let listaUsuariosExcluir = [];
            listaUsuariosExcluir.push({
                idUsuario: this.listaUsuarios[index].usuario.id,
                idUnimed: this.listaUsuarios[index].unimed.id
            });

            let data = {
                papeis: this.montarPapeisExcluir(),
                usuarioUnimeds: listaUsuariosExcluir
            };

            this.excluirUsuarioPapel(data);
        },
        paginar() {
            if (this.papeis.find(papel => papel.selecionado)) {
                this.getListaUsuarios(this.getArgs());
                this.checkCorpoProp = [];
            } else {
                this.listaUsuarios = [];
                this.rows = [];
                this.checkCorpoProp = [];
                this.montaTabela({ conteudo: [] });
            }
        },
        getArgs() {
            return {
                idPapel: this.getPapeisSelecionados(),
                idUnimed: this.getUnimedSelecionada(),
                idTipoUsuario: this.getTipoUsuarioSelecionado(),
                descricao: this.campoPesquisa,
                pagina: this.pagina
            };
        },
        getPapeisSelecionados() {
            let papeisSelecionados = [];
            this.papeis.forEach(item => {
                item.selecionado ? papeisSelecionados.push(item.id) : "";
            });
            return papeisSelecionados;
        },
        getUnimedSelecionada() {
            return this.filtroUsuario.unimed != ""
                ? this.listaUnimeds.find(unimed => unimed.codigoNome === this.filtroUsuario.unimed).codigo
                : "";
        },
        getTipoUsuarioSelecionado() {
            return this.filtroUsuario.tipo != ""
                ? this.listaTiposUsuarios.find(tipo => tipo.descricao === this.filtroUsuario.tipo).codigo
                : "";
        },
        checkTabela(valor) {
            this.selecionados = valor;
        },
        excluirMultiplosUsuarios() {
            let data = {
                papeis: this.montarPapeisExcluir(),
                usuarioUnimeds: this.montarUsuariosExcluir()
            };
            this.excluirUsuarioPapel(data);
        },

        excluirUsuarioPapel(data) {
            this.loading = true;
            this.deleteUsuarioPapel(data)
                .then(() => {
                    this.paginar();
                    this.checkCorpoProp = [];
                    this.mensagemModalSucesso = "Papel excluido com sucesso.";
                    this.mostrarModalSucesso = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("atualizar");
                });
        },

        montarUsuariosExcluir() {
            let listaUsuariosExcluir = [];
            this.selecionados.forEach(item => {
                listaUsuariosExcluir.push({
                    idUsuario: this.listaUsuarios[item].usuario.id,
                    idUnimed: this.listaUsuarios[item].unimed.id
                });
            });
            return listaUsuariosExcluir;
        },

        montarPapeisExcluir() {
            let listaPapeisExcluir = [];
            this.papeis.forEach(item => {
                if (item.selecionado) {
                    listaPapeisExcluir.push(item.id);
                }
            });
            return listaPapeisExcluir;
        },
        // FILTRO
        alterarFiltro() {
            this.unimedSelecionada = "";
            this.tipoSelecionado = "";
            this.filtroUsuario.unimed = "";
            this.filtroUsuario.tipo = "";
            this.mostrarFiltroAvancado ? (this.mostrarFiltroAvancado = false) : (this.mostrarFiltroAvancado = true);
        },
        pesquisarAplicacao(valor) {
            this.pagina = 1;
            this.campoPesquisa = valor.replace(/([./-])/g, "");
            this.paginar();
        },
        excluirTodos() {
            this.loading = true;
            this.deleteExcluirTodosPapel(this.getArgs())
                .then(() => {
                    this.paginar();
                    this.checkCorpoProp = [];
                    this.mensagemModalSucesso = "Papéis excluido com sucesso.";
                    this.mostrarModalSucesso = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("atualizar");
                });
        },
        filtrar() {
            this.pagina = 1;
            this.paginar();
        }
    }
};
</script>

<style lang="scss" scoped>
.cabecalho-tabela {
    .linha-papeis-selecionados {
        margin-bottom: 16px;

        p,
        small {
            font-size: 16px;
        }

        span {
            color: #00995d;
        }
    }

    .linha-filtro-avancado {
        margin-top: 16px;

        .botao {
            display: block;
            margin-top: 24px;
        }
    }
}

.container-selecao {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid #f1f2f1;
    background-color: #f1f2f1;

    & .icone-excluir {
        box-sizing: border-box;
        padding-left: 4px;
        width: 26px;
        border-radius: 20px;
        cursor: pointer;
        &:hover {
            color: #f0666f;
        }
    }

    & .selecionar-todos {
        cursor: pointer;
        color: #1492e6;
    }
}
</style>
