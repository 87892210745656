<template>
    <unicom-modal v-model="mostrar" :largura="644" fechar @input="valor => fecharModal(valor)">
        <div slot="header">
            <h3 class="fonte-primaria titulo negrito">Tem certeza?</h3>
        </div>
        <div slot="body" class="fonte-secundaria medio modal-corpo">
            <slot name="corpo">
                <p>Será gerado um novo {{ tipoDado }} para a aplicação/conta de serviço.</p>
            </slot>
            <p class="negrito importante">Essa ação é irreversível!</p>
        </div>
        <div slot="footer">
            <unicom-linha :espacamento="true" :justificarItem="'center'">
                <unicom-coluna :md="4">
                    <unicom-botao class="fonte-secundaria texto" :tipo="'secundario'" :bloco="true" @click="gerarNovo()"
                        >GERAR NOVO</unicom-botao
                    >
                </unicom-coluna>
                <unicom-coluna :md="3">
                    <unicom-botao class="fonte-secundaria texto" :bloco="true" @click="mostrar = false"
                        >FECHAR</unicom-botao
                    >
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        clientId: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            mostrar: this.value
        };
    },
    computed: {
        tipoDado() {
            return this.clientId ? "client id" : "client secret";
        }
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },
        gerarNovo() {
            if (this.clientId) {
                this.$emit("client-id");
            } else {
                this.$emit("client-secret");
            }
            this.$emit("fechar");
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    text-align: center;
}

p {
    line-height: 25px;

    &.importante {
        color: #f0666f;
    }
}

.modal-corpo {
    margin: 24px 0;
    text-align: center;
}
</style>
