<template>
    <unicom-linha :espacamento="false">
        <unicom-coluna :lg="12">
            <unicom-cartao :class="`cartao-cadastrar-aplicacao ${tamanhoTela}`">
                <div slot="body">
                    <TabelaAplicacao
                        @cadastrar-aplicacao="cadastrarAplicacao()"
                        @editar="aplicacao => editarAplicacao(aplicacao)"
                        @put-aplicacao-sucesso="aplicacao => modalSucesso(aplicacao)"
                        ref="tabelaAplicacao"
                    />
                </div>
            </unicom-cartao>
            <ModalCadatroAplicacao
                v-model="mostrarModal"
                @fechar="mostrarModal = false"
                @adicionar="aplicacao => adicionarAplicacao(aplicacao)"
            />
            <unicom-modal-sucesso-acao
                v-model="mostrarModalSucesso"
                textoBotaoAcao="Atribuir usuários"
                textoSucesso="A aplicação foi cadastrada!"
                @fechar="mostrarModalSucesso = false"
                @evento-acao="botaoAcaoModalSucesso"
            />
        </unicom-coluna>
    </unicom-linha>
</template>

<script>
import TabelaAplicacao from "./Shared/TabelaAplicacao.vue";
import ModalCadatroAplicacao from "./Shared/ModalCadastroAplicacao";

export default {
    components: {
        TabelaAplicacao,
        ModalCadatroAplicacao
    },
    data() {
        return {
            tamanhoJanela: 0,
            mostrarModal: false,
            mostrarModalSucesso: false,
            aplicacaoCriada: {}
        };
    },
    created() {
        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },
    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },
    computed: {
        tamanhoTela() {
            if (this.tamanhoJanela < 576) {
                return "ativo mobile";
            } else {
                return "web";
            }
        }
    },
    methods: {
        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        cadastrarAplicacao() {
            this.mostrarModal = true;
        },
        adicionarAplicacao(aplicacao) {
            this.$refs.tabelaAplicacao.setAplicacao(aplicacao);
        },
        modalSucesso(aplicacao) {
            this.aplicacaoCriada = aplicacao;
            this.mostrarModalSucesso = true;
        },
        botaoAcaoModalSucesso() {
            this.$emit("criada-aplicacao-atribuir-papel", this.aplicacaoCriada);
        },
        editarAplicacao(aplicacao) {
            this.$emit("editar-aplicacao", aplicacao);
        }
    }
};
</script>

<style lang="scss" scoped>
.cartao-cadastrar-aplicacao {
    min-height: 400px;
    border-radius: 20px;
}
</style>
