<template>
    <unicom-cartao class="container-lista">
        <div slot="header" class="container-cabecalho">
            <unicom-linha :espacamento="false">
                <unicom-coluna>
                    <h3 class="fonte-primaria subtitulo negrito">PAPÉIS DA APLICAÇÃO</h3>
                </unicom-coluna>
            </unicom-linha>
        </div>
        <div slot="body" class="container-corpo">
            <unicom-linha :espacamento="false">
                <unicom-coluna>
                    <ul class="fonte-secundaria texto" v-if="papeis.length > 0">
                        <li
                            v-for="(item, index) in listaPapeis"
                            :key="index"
                            :class="itemSelecionado(item.selecionado)"
                            @click="selecionarPapel(item.selecionado, index)"
                        >
                            <unicom-linha :espacamento="false">
                                <unicom-coluna sm="9" alinhamento="center">
                                    <div class="texto">{{ item.nome }}</div>
                                </unicom-coluna>

                                <unicom-coluna sm="auto" alinhamento="center" style="margin-left: auto">
                                    <div class="quantidade">{{ item.quantidade || 0 }}</div>
                                </unicom-coluna>
                            </unicom-linha>
                        </li>
                    </ul>

                    <div class="nao-ha-dados fonte-secundaria texto" v-else>
                        <p class="medio negrito">Nenhum papel cadastrado.</p>
                        <p>Clique em cadastrar novo papel para continuar</p>
                        <unicom-botao :bloco="true" @click="$emit('adicionar-papel')"
                            >CADASTRAR NOVO PAPEL</unicom-botao
                        >
                    </div>
                </unicom-coluna>
            </unicom-linha>
        </div>

        <div slot="footer" class="container-rodape">
            <small class="fonte-secundaria texto" v-if="papeis.length > 0" @click="$emit('adicionar-papel')">
                gerenciar papeis
            </small>
        </div>
    </unicom-cartao>
</template>

<script>
export default {
    props: {
        papeis: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            listaPapeis: this.papeis
        };
    },
    methods: {
        selecionarPapel(selecao, index) {
            this.listaPapeis[index].selecionado = !this.listaPapeis[index].selecionado;
            this.$emit("click", { selecionado: this.listaPapeis[index].selecionado, posicao: index });
        },
        itemSelecionado(selecao) {
            return selecao ? "selecionado" : "nao-selecionado";
        }
    },
    watch: {
        papeis() {
            this.listaPapeis = this.papeis;
        }
    }
};
</script>

<style lang="scss" scoped>
.container-lista {
    position: relative;
    height: 100%;
    padding: 24px 4px;

    .container-cabecalho {
        padding-left: 16px;
        height: 20px;
        color: #00995d;
    }

    .container-corpo {
        height: 360px;
        padding: 4px;
        overflow-x: hidden;
        overflow-y: auto;

        & ul {
            & li {
                box-sizing: border-box;
                width: 100%;
                margin: 6px 0;
                padding: 4px 7px 4px 16px;
                border-radius: 5px;
                border: 1px solid #dee2e6;
                background-color: #ffffff;
                color: #60656c;
                text-align: left;
                cursor: pointer;

                & div {
                    box-sizing: border-box;
                    height: 100%;

                    &.texto {
                        word-break: break-all;
                    }

                    &.quantidade {
                        padding: 6px 4px;
                        min-width: 30px;
                        border-radius: 5px;
                        background-color: #f1f2f1;
                        color: #60656c;
                        text-align: center;
                    }
                }

                &.selecionado {
                    background-color: #00995d;
                    color: #fff;
                }
            }
        }

        & .nao-ha-dados {
            margin: 135px 16px;
            box-sizing: border-box;
            color: #60656c;

            & p {
                margin-bottom: 8px;
            }
        }
    }

    .container-rodape {
        height: 20px;
        padding-left: 16px;
        color: #1492e6;

        & small {
            cursor: pointer;
        }
    }
}
</style>
