<template>
    <div>
        <GerenciarAplicacoes />
    </div>
</template>

<script>
import GerenciarAplicacoes from "../components/gerenciarAplicacoes/GerenciarAplicacoes.vue";
export default {
    components: {
        GerenciarAplicacoes
    }
};
</script>

<style lang="scss" scoped></style>
