<template>
    <div class="steps-container">
        <unicom-linha :espacamento="false">
            <unicom-coluna :sm="12" md="auto" v-for="(item, index) in abas" :key="index" class="coluna">
                <button
                    :class="`step ${tamanhoTela} ${habilitar(item.atual)}`"
                    :disabled="!item.ativo"
                    @click="$emit('click', index)"
                >
                    <h3 class="fonte-secundaria subtitulo">{{ item.nome }}</h3>
                </button>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
export default {
    props: {
        abas: {
            type: Array,
            required: true,
            note: "Recebe os itens que formam as abas dos steps, deve ser um objeto com: nome e ativo"
        },
        tamanhoTela: {
            type: String,
            required: false,
            default: "web",
            note: "Recebe valores referentes ao tamanho da tela (web ou mobile)"
        }
    },
    methods: {
        habilitar(atual) {
            return atual ? "atual" : "";
        }
    }
};
</script>

<style lang="scss" scoped>
.steps-container {
    box-sizing: border-box;
    width: 100%;
    height: 54px;
    border-radius: 20px 20px 0 0;
    border-bottom: 1px solid #9e9e9e80;
    background-color: #fff;
    .step {
        box-sizing: border-box;
        width: 100%;
        padding-left: 45px;
        padding-right: 45px;
        background-color: transparent;
        color: #9e9e9e;
        text-align: center;
        cursor: pointer;

        &.mobile {
            border-radius: 8px 8px 0px 0px;
        }

        &:enabled {
            &:hover,
            &.atual {
                border-bottom: 2px solid #00995d;
                width: 100%;
                color: #00995d;
            }
        }

        &:disabled {
            background-color: #dee2e6;
            cursor: not-allowed;
        }

        h3 {
            margin: 24px 0 13px 0;
        }
    }
}

.coluna {
    padding: 0px;
}
</style>
