<template>
    <div>
        <unicom-linha :espacamento="false">
            <unicom-coluna class="conteudo-principal-container">
                <Steps :abas="abas" :tamanhoTela="tamanhoTela" @click="index => trocaStep(index)" />
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha :espacamento="false">
            <unicom-coluna :lg="12">
                <unicom-cartao :class="`cartao-cadastrar-aplicacao ${tamanhoTela}`">
                    <div slot="body">
                        <DadosBasicos
                            v-show="index === 0"
                            :aplicacaoEditada="aplicacao"
                            @novo-client-id="gerarClientId()"
                            @novo-client-secret="gerarClientSecret()"
                            ref="dadosBasicos"
                        />
                        <Papeis
                            v-if="index === 1"
                            :id="aplicacao.id"
                            :papeis="aplicacao.papeisAplicacao"
                            @atualizar="$emit('atualizar-aplicacao', aplicacao.id)"
                        />
                    </div>
                    <div slot="footer">
                        <unicom-linha :espacamento="true">
                            <unicom-coluna :md="2">
                                <unicom-botao
                                    :tipo="'primario'"
                                    :contornado="true"
                                    class="fonte-secundaria texto"
                                    @click="$emit('fechar')"
                                >
                                    CANCELAR
                                </unicom-botao>
                            </unicom-coluna>
                            <unicom-coluna :md="10" v-if="index === 0">
                                <unicom-linha :espacamento="true" :justificarItem="'end'">
                                    <unicom-coluna :md="3">
                                        <unicom-botao
                                            :tipo="'primario'"
                                            :bloco="true"
                                            class="fonte-secundaria texto"
                                            @click="verificarValidacoes()"
                                        >
                                            SALVAR ALTERAÇÕES
                                        </unicom-botao>
                                    </unicom-coluna>
                                </unicom-linha>
                            </unicom-coluna>
                        </unicom-linha>
                    </div>
                </unicom-cartao>
            </unicom-coluna>
        </unicom-linha>
        <ModalToken
            v-model="mostrarModal"
            :clientId="clientId"
            @fechar="mostrarModal = false"
            @client-id="renovarClientId()"
            @client-secret="renovarClientSecret()"
        />
        <ModalSucessoClientId
            v-model="mostrarSucessoClientId"
            @fechar="mostrarSucessoClientId = false"
            :clientId="this.aplicacao.clientId"
        />
        <ModalSucessoClientSecret
            v-model="mostrarSucessoClientSecret"
            @fechar="mostrarSucessoClientSecret = false"
            :clientSecret="this.aplicacao.clientSecret"
        />
    </div>
</template>

<script>
import Aplicacao from "@/services/gerenciarAplicacoes/Aplicacao";
import AplicacoesServiceMixin from "../../../../mixins/services/aplicacoes/AplicacoesServiceMixin";

import Steps from "./Shared/Steps";
import DadosBasicos from "./Shared/DadosBasicos";
import Papeis from "./Shared/Papeis";
import ModalToken from "./Shared/ModalToken";
import ModalSucessoClientId from "./Shared/ModalSucessoClientId";
import ModalSucessoClientSecret from "./Shared/ModalSucessoClientSecret";

export default {
    components: {
        Steps,
        DadosBasicos,
        Papeis,
        ModalToken,
        ModalSucessoClientId,
        ModalSucessoClientSecret
    },
    mixins: [AplicacoesServiceMixin],
    props: {
        aplicacaoSelecionada: {
            type: Object,
            required: false,
            note: "Aplicação que será visualizada e editada"
        },
        trocarParaPapeis: {
            type: Boolean,
            required: false,
            default: false,
            note:
                "Caso seja uma aplicação nova e usuário clicou em atribuir usuários na modal de usuários ira pra step de Papes/Usuários"
        }
    },
    data() {
        return {
            tamanhoJanela: 0,
            abas: [
                {
                    nome: "DADOS BÁSICOS",
                    ativo: true,
                    atual: true
                },
                {
                    nome: "PAPÉIS / USUÁRIOS",
                    ativo: true,
                    atual: false
                }
            ],
            index: 0,
            aplicacao: this.aplicacaoSelecionada,
            clientId: false,
            mostrarModal: false,
            mostrarSucessoClientId: false,
            mostrarSucessoClientSecret: false
        };
    },
    created() {
        window.addEventListener("resize", this.redimensionar);
        this.desejaAtribuirUsuario();
        this.redimensionar();
    },
    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },
    computed: {
        tamanhoTela() {
            if (this.tamanhoJanela < 576) {
                return "ativo mobile";
            } else {
                return "web";
            }
        }
    },
    methods: {
        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        desejaAtribuirUsuario() {
            if (this.trocarParaPapeis) {
                this.trocaStep(1);
            }
        },
        trocaStep(index) {
            this.abas[this.index].atual = false;
            this.index = index;
            this.abas[index].atual = true;
        },
        async verificarValidacoes() {
            let valido = await this.$refs.dadosBasicos.validarDados();
            if (valido) {
                this.salvarAlteracoes();
            }
        },
        salvarAlteracoes() {
            let id = this.$refs.dadosBasicos.aplicacao.id;

            let aplicacao = {
                ativa: this.$refs.dadosBasicos.aplicacao.ativa,
                descricao: this.$refs.dadosBasicos.aplicacao.descricao,
                nome: this.$refs.dadosBasicos.aplicacao.nome,
                papeisAplicacao: this.$refs.dadosBasicos.aplicacao.papeisAplicacao,
                url: this.$refs.dadosBasicos.aplicacao.url,
                quantidadeUnimed: this.$refs.dadosBasicos.comunicacao.quantidadeUsuarios > 0 && this.$refs.dadosBasicos.comunicacao.quantidadeUsuarios > 0 ? this.$refs.dadosBasicos.comunicacao.quantidadeUsuarios : null
            };
            this.putListaAplicacoes(id, aplicacao);
        },
        async putListaAplicacoes(id, aplicacao) {
            let aplicacaoPut = new Aplicacao(this.$appConfig.config.API);
            try {
                await aplicacaoPut.putAplicacoes(id, aplicacao);
                this.$emit("fechar");
            } catch (err) {
                console.error(err);
            }
        },
        gerarClientId() {
            this.clientId = true;
            this.mostrarModal = true;
        },
        renovarClientId() {
            this.postNovoClientId(this.aplicacao.id)
                .then(() => {
                    this.$emit("atualizar-client-id", this.aplicacao.id);
                })

        },
        atualizarClientId(aplicacao) {
            this.$refs.dadosBasicos.clientIdGerado(aplicacao.clientId);
            this.mostrarSucessoClientId = true;
        },
        gerarClientSecret() {
            this.clientId = false;
            this.mostrarModal = true;
        },
        renovarClientSecret() {
            this.postNovoClientSecret(this.aplicacao.id)
                .then(() => {
                    this.$emit("atualizar-client-secret", this.aplicacao.id);
                })
        },
        atualizarClientSecret(aplicacao) {
            this.$refs.dadosBasicos.clientSecretGerado(aplicacao.clientSecret);
            this.mostrarSucessoClientSecret = true;
        }
    },
    watch: {
        aplicacaoSelecionada() {
            this.aplicacao = this.aplicacaoSelecionada;
        }
    }
};
</script>

<style lang="scss" scoped>
.cartao-cadastrar-aplicacao {
    min-height: 400px;
    border-radius: 0px 0px 20px 20px;
}
</style>
