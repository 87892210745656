<template>
    <div>
        <unicom-modal v-model="mostrar" :largura="903" fechar @input="valor => fecharModal(valor)" class="modal">
            <div slot="header">
                <h3 class="fonte-primaria titulo negrito">ADICIONAR USUÁRIOS</h3>
            </div>
            <div slot="body" class="container-corpo">
                <div class="cabecalho-tabela">
                    <unicom-linha :espacamento="true" :justificarItem="'start'">
                        <unicom-coluna :md="9">
                            <unicom-barra-pesquisa
                                :value="campoPesquisa"
                                :placeholder="'Buscar Login ou Nome...'"
                                @pesquisar="valor => pesquisarAplicacao(valor)"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="3">
                            <unicom-botao
                                :bloco="true"
                                :tipo="'primario'"
                                contornado
                                class="fonte-secundaria texto"
                                @click="alterarFiltro()"
                            >
                                filtro avançado
                            </unicom-botao>
                        </unicom-coluna>
                    </unicom-linha>

                    <unicom-linha :espacamento="false" class="linha-filtro-avancado" v-if="mostrarFiltroAvancado">
                        <unicom-coluna :md="4" style="margin-right: 16px">
                            <unicom-autocomplete
                                v-model="filtroUsuario.unimed"
                                :valorData="filtroUsuario.unimed"
                                :items="listaUnimeds"
                                titulo="Unimed"
                                textoDoItem="codigoNome"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="4" style="margin-right: 16px">
                            <unicom-autocomplete
                                v-model="filtroUsuario.tipo"
                                :valorData="filtroUsuario.tipo"
                                :items="listaTiposUsuarios"
                                titulo="Tipo do usuário"
                                textoDoItem="descricao"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="3">
                            <unicom-botao :bloco="true" class="fonte-secundaria texto botao" @click="filtrar()">
                                FILTRAR
                            </unicom-botao>
                        </unicom-coluna>
                    </unicom-linha>
                </div>
                <div class="fonte-secundaria texto container-selecao" v-if="selecionados.length > 1">
                    <unicom-linha espacamento>
                        <unicom-coluna md="12">
                            <span class="negrito">{{ quantidadeSelecionados }} usuários</span> desta página serão
                            selecionados.
                            <span class="negrito selecionar-todos" @click="adicionarTodos()"
                                >Adicionar todos os {{ qtResultados }} usuários.</span
                            >
                        </unicom-coluna>
                    </unicom-linha>
                </div>
                <unicom-tabela
                    mostrarCheck
                    mostrarSelecionados
                    :exibirHeader="false"
                    :checkCorpoProp="checkCorpoProp"
                    :carregamento="loading"
                    :icones="iconesUsadosTabela"
                    :pagina="pagina"
                    :qtPaginas="qtPaginas"
                    :qtResultados="qtResultados"
                    :rows="rows"
                    :cabecalho="cabecalho"
                    :textoExibindo="'usuários'"
                    @troca-pagina="item => trocaPagina(item)"
                    @acao="item => acao(item)"
                    @check="valor => checkTabela(valor)"
                >
                </unicom-tabela>
            </div>
            <div slot="footer" class="footer" style="margin-top:24px">
                <unicom-linha :espacamento="false">
                    <unicom-coluna :md="6">
                        <unicom-linha :espacamento="false" justificarItem="start">
                            <unicom-coluna :md="6">
                                <unicom-botao
                                    class="fonte-secundaria texto"
                                    tipo="contornado-primario"
                                    :bloco="true"
                                    @click="mostrar = false"
                                >
                                    CANCELAR
                                </unicom-botao>
                            </unicom-coluna>
                        </unicom-linha>
                    </unicom-coluna>
                    <unicom-coluna :md="6">
                        <unicom-linha :espacamento="false" justificarItem="end">
                            <unicom-coluna :md="6">
                                <unicom-botao
                                    class="fonte-secundaria texto"
                                    :bloco="true"
                                    :desabilitado="liberarAdicionarPapel"
                                    @click="adicionarUsuarios()"
                                >
                                    ADICIONAR AO PAPEL
                                </unicom-botao>
                            </unicom-coluna>
                        </unicom-linha>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-modal>
    </div>
</template>

<script>
import UnimedServiceMixin from "@/mixins/services/dominios/UnimedServiceMixin";
import UsuarioAplicacaoServiceMixin from "./services/UsuarioAplicacaoServiceMixin";

export default {
    mixins: [UnimedServiceMixin, UsuarioAplicacaoServiceMixin],
    props: {
        value: {
            type: Boolean,
            required: true
        },
        papeis: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            carregamentoOverlay: false,
            mostrar: this.value,

            // FILTRO
            campoPesquisa: "",
            mostrarFiltroAvancado: false,
            unimedSelecionada: "",
            tipoSelecionado: "",
            filtroUsuario: {
                unimed: "",
                tipo: ""
            },
            listaUnimeds: [],
            listaTiposUsuarios: [],

            // DADOS
            listaUsuarios: [],

            // TABELA
            loading: true,
            checkCorpoProp: [],
            iconesUsadosTabela: [],
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            rows: [],
            cabecalho: [
                {
                    texto: "Nome"
                },
                {
                    texto: "Login"
                },
                {
                    texto: "Unimed"
                }
            ],
            selecionados: []
        };
    },
    beforeMount() {
        this.paginar();
        this.getDominios();
    },
    computed: {
        quantidadeSelecionados() {
            return this.selecionados.length;
        },
        liberarAdicionarPapel() {
            return this.selecionados.length <= 0;
        }
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.limparModal();
                this.$emit("fechar");
            }
        },

        limparModal() {
            this.mostrarFiltroAvancado = false;
            this.campoPesquisa = "";
            this.unimedSelecionada = "";
            this.tipoSelecionado = "";
            this.filtroUsuario = {
                unimed: "",
                tipo: ""
            };
        },

        adicionarUsuarios() {
            this.$store.commit('setLoading', true)
            let data = {
                papeis: this.montarPapeisAdicionar(),
                usuarioUnimeds: this.montarUsuariosAdicionar()
            };
            this.postAdicionarUsuarioPapel(data)
                .then(() => {
                    this.fecharModal();
                    this.$emit("recarregar");
                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                })
        },

        montarUsuariosAdicionar() {
            let listaUsuariosAdicionar = [];
            this.selecionados.forEach(item => {
                listaUsuariosAdicionar.push({
                    idUsuario: this.listaUsuarios[item].usuario.id,
                    idUnimed: this.listaUsuarios[item].unimed.id
                });
            });
            return listaUsuariosAdicionar;
        },

        montarPapeisAdicionar() {
            let listaPapeisAdicionar = [];
            this.papeis.forEach(item => {
                if (item.selecionado) {
                    listaPapeisAdicionar.push(item.id);
                }
            });
            return listaPapeisAdicionar;
        },

        // GETS
        getListaUsuarios(args) {
            this.loading = true;
            this.getUsuarioExclusoPapel(args)
                .then(response => {
                    this.montaTabela(response.data);
                    this.checkCorpoProp = []
                    this.loading = false;
                })
                .catch(error => {
                    console.error(error);
                    this.listaUsuarios = [];
                    this.rows = [];
                    this.loading = false;
                });
        },
        getDominios() {
            this.getListaUnimeds();
            this.getListaTiposUsuarios();
        },
        getListaUnimeds() {
            this.listaUnimeds = this.getUnimedsList();
        },
        getListaTiposUsuarios() {
            this.getTiposUsuarios()
                .then(response => {
                    this.listaTiposUsuarios = response.data;
                })
                .catch(error => {
                    console.error(error);
                    this.listaTiposUsuarios = [];
                });
        },

        // FILTRO
        pesquisarAplicacao(valor) {
            this.pagina = 1
            this.campoPesquisa = valor.replace(/([./-])/g, "");
            this.paginar();
        },
        alterarFiltro() {
            this.unimedSelecionada = "";
            this.tipoSelecionado = "";
            this.filtroUsuario.unimed = "";
            this.filtroUsuario.tipo = "";
            this.mostrarFiltroAvancado ? (this.mostrarFiltroAvancado = false) : (this.mostrarFiltroAvancado = true);
        },

        montaTabela(objeto) {
            this.listaUsuarios = [];
            this.rows = [];

            this.listaUsuarios = objeto.conteudo;
            this.listaUsuarios.forEach(item => {
                this.rows.push({
                    nomeUsuario: {
                        texto: item.usuario.nome,
                        qtdMaxCaracteres: 40
                    },
                    documentoUsuario: {
                        texto: item.usuario.documento.numeroDocumento,
                        qtdMaxCaracteres: 24
                    },
                    unimedUsuario: {
                        texto: item.unimed.nome,
                        qtdMaxCaracteres: 40
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },
        trocaPagina(item) {
            this.pagina = item.pagina;
            this.paginar();
        },
        paginar() {
            if (this.papeis.find(papel => papel.selecionado)) {
                this.getListaUsuarios(this.getArgs());
            } else {
                this.listaUsuarios = [];
                this.rows = [];
            }
        },
        getArgs() {
            return {
                idPapel: this.getPapeisSelecionados(),
                idUnimed: this.getUnimedSelecionada(),
                idTipoUsuario: this.getTipoUsuarioSelecionado(),
                descricao: this.campoPesquisa,
                pagina: this.pagina
            };
        },
        getPapeisSelecionados() {
            let papeisSelecionados = [];
            this.papeis.forEach(item => {
                item.selecionado ? papeisSelecionados.push(item.id) : "";
            });
            return papeisSelecionados;
        },
        getUnimedSelecionada() {
            return this.filtroUsuario.unimed != ""
                ? this.listaUnimeds.find(unimed => unimed.codigoNome === this.filtroUsuario.unimed).codigo
                : "";
        },
        getTipoUsuarioSelecionado() {
            return this.filtroUsuario.tipo != ""
                ? this.listaTiposUsuarios.find(tipo => tipo.descricao === this.filtroUsuario.tipo).codigo
                : "";
        },
        checkTabela(valor) {
            this.selecionados = valor;
        },
        adicionarTodos() {
            this.$store.commit('setLoading', true)
            this.postAdicionarTodosExclusoPapel(this.getArgs())
                .then(() => {
                    this.fecharModal();
                    this.$emit("recarregar");
                })
                .finally(() => {
                    this.$store.commit('setLoading', false)
                })
        },
        filtrar(){
            this.pagina = 1
            this.paginar()
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
            if (this.mostrar) {
                this.paginar();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #00995d;
    margin-bottom: 30px;
}

.texto-vermelho {
    color: #f0666f;
}

.cabecalho-tabela {
    .linha-papeis-selecionados {
        margin-bottom: 16px;

        p,
        small {
            font-size: 16px;
        }

        span {
            color: #00995d;
        }
    }

    .linha-filtro-avancado {
        margin-top: 16px;

        .botao {
            display: block;
            margin-top: 24px;
        }
    }
}

.container-selecao {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid #f1f2f1;
    background-color: #f1f2f1;

    & .selecionar-todos {
        cursor: pointer;
        color: #1492e6;
    }
}
</style>
