<template>
    <ModalSucesso v-model="mostrar" :titulo="'Sucesso!'" @fechar="fecharModal()">
        <div slot="conteudo">
            <unicom-linha :espacamento="false" :justificarItem="'center'">
                <unicom-coluna>
                    <p>Um novo client secret foi gerado para a aplicação/conta de serviço:</p>
                </unicom-coluna>
                <unicom-coluna :md="10">
                    <div class="campo-aplicacao">
                        <unicom-campo-texto v-model="clientSecret" :desativado="true" />
                    </div>
                    <div class="copiar-aplicacao" @click="copiarDado(clientSecret)">
                        <unicom-icone :nome="'copy'" :tamanho="22" />
                    </div>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </ModalSucesso>
</template>

<script>
import ModalSucesso from "../../../../shared/modalSucesso/ModalSucesso";

export default {
    components: {
        ModalSucesso
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        clientSecret: {
            type: String,
            required: false,
            default: "",
            note: "Novo token da aplicação"
        }
    },
    data() {
        return {
            mostrar: this.value
        };
    },
    methods: {
        copiarDado(dado) {
            let inputCopiar = document.createElement("input");
            inputCopiar.value = dado;

            document.body.appendChild(inputCopiar);
            inputCopiar.select();

            document.execCommand("copy");
            document.body.removeChild(inputCopiar);
        },
        fecharModal() {
            this.mostrar = false;
            this.$emit("fechar");
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.campo-aplicacao {
    float: left;
    margin-top: 14px;
    box-sizing: border-box;
    width: 85%;
}

.copiar-aplicacao {
    float: left;
    margin-top: 24px;
    margin-left: 1%;
    box-sizing: border-box;
    width: 10%;
    color: #ccc;
    cursor: pointer;

    &:hover {
        color: #00995d;
    }
}
</style>
