<template>
    <unicom-modal v-model="mostrar" :largura="691" fechar @input="valor => fecharModal(valor)">
        <div slot="header">
            <h3 class="fonte-primaria titulo negrito">GERENCIAR PAPÉIS DA APLICAÇÃO</h3>
        </div>
        <div slot="body" class="container-corpo">
            <ValidationObserver ref="observer">
                <unicom-coluna>
                    <unicom-linha :espacamento="false" class="fonte-secundaria texto negrito tabela-cabecalho">
                        <unicom-coluna :md="3" class="coluna"> Nome do papel </unicom-coluna>

                        <unicom-coluna :md="4" class="coluna"> Descrição </unicom-coluna>

                        <unicom-coluna :md="2" class="coluna">
                            <span class="centralizada">Restrito</span>
                        </unicom-coluna>

                        <unicom-coluna :md="3" class="coluna">
                            <span class="acao-centralizada">Ações</span>
                        </unicom-coluna>
                    </unicom-linha>

                    <unicom-linha
                        :espacamento="false"
                        :class="`fonte-secundaria texto tabela-linha ${linhaEdicao(papel)}`"
                        v-for="(papel, index) in listaPapeis"
                        :key="index"
                    >
                        <template v-if="!papel.edicao">
                            <unicom-coluna :md="3" class="coluna exibicao" style="overflow-wrap: break-word;">
                                {{ papel.nome }}
                            </unicom-coluna>

                            <unicom-coluna :md="4" class="coluna exibicao"> {{ papel.descricao }} </unicom-coluna>

                            <unicom-coluna :md="2" class="coluna">
                                <div class="centralizada">
                                    <div class="icone-container">
                                        <unicom-icone
                                            :nome="papel.adminOnly ? 'lock' : 'unlock'"
                                            :tamanho="15"
                                            :tooltipNome="
                                                papel.adminOnly
                                                    ? 'Uso exclusivo dos Administradores da UB'
                                                    : 'Disponível para atribuição geral'
                                            "
                                        />
                                    </div>
                                </div>
                            </unicom-coluna>
                            <unicom-coluna :md="3" class="coluna">
                                <div class="acao-centralizada">
                                    <div
                                        class="icone-container adicionar"
                                        :class="edicaoAberta ? 'inativa' : ''"
                                        @click="editarPapel(index)"
                                    >
                                        <unicom-icone :nome="'edit'" :tamanho="15" :tooltipNome="'Editar papel'" />
                                    </div>
                                    <div
                                        class="icone-container exclusao"
                                        :class="edicaoAberta ? 'inativa' : listaPapeis.length === 1 ? 'inativa' : ''"
                                        @click="excluirPapel(index)"
                                    >
                                        <unicom-icone :nome="'trash'" :tamanho="15" :tooltipNome="'Excluir papel'" />
                                    </div>
                                    <div
                                        class="icone-container copiar"
                                        :class="edicaoAberta ? 'inativa' : ''"
                                        @click="copiarIdPapel(papel)"
                                    >
                                        <unicom-icone
                                            :nome="'copy'"
                                            :tamanho="15"
                                            :tooltipNome="'Copiar id do papel'"
                                        />
                                    </div>
                                </div>
                            </unicom-coluna>
                        </template>
                        <template v-else>
                            <unicom-coluna :md="4" class="coluna">
                                <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                    <unicom-campo-texto
                                        v-model="papel.nome"
                                        :placeholder="'Insira o nome do papel'"
                                        :maxCaracteres="'40'"
                                        :apenasAlfaNumerico="true"
                                        class="campo-texto"
                                        :mensagemErro="errors[0]"
                                    />
                                </validation-provider>
                            </unicom-coluna>
                            <unicom-coluna :md="5" class="coluna">
                                <unicom-campo-texto
                                    v-model="papel.descricao"
                                    :placeholder="'Insira a descrição do papel'"
                                    :maxCaracteres="'62'"
                                    :apenasAlfaNumerico="true"
                                    class="campo-texto"
                                />
                            </unicom-coluna>
                            <unicom-coluna :md="3" class="coluna">
                                <div class="acao-centralizada">
                                    <div class="icone-container adicionar" @click="confirmar(index, papel)">
                                        <unicom-icone
                                            :nome="'check'"
                                            :tamanho="15"
                                            :tooltipNome="'Confirmar edição'"
                                            class="icone"
                                        />
                                    </div>
                                    <div class="icone-container exclusao" @click="cancelarEdicao(index)">
                                        <unicom-icone
                                            :nome="'x'"
                                            :tamanho="15"
                                            :tooltipNome="'Cancelar edição'"
                                            class="icone"
                                        />
                                    </div>
                                    <div
                                        class="icone-container admin-apenas"
                                        @click="atualizarApenasAdministrador(index)"
                                    >
                                        <unicom-tooltip tooltipLocalizacao="top-right">
                                            <div slot="itemTooltip">
                                                <unicom-icone :nome="adminOnly ? 'lock' : 'unlock'" :tamanho="15" />
                                            </div>
                                            <div slot="conteudo-tooltip">
                                                <p>Quando trancado, apenas a Unimed do Brasil,</p>
                                                <p>poderá atribuir usuários neste papel</p>
                                            </div>
                                        </unicom-tooltip>
                                    </div>
                                </div>
                            </unicom-coluna>
                        </template>
                    </unicom-linha>
                </unicom-coluna>
            </ValidationObserver>
            <small :class="`fonte-secundaria texto ${edicaoAberta}`" @click="adicionarPapelLista()"
                >+ cadastrar novo papel</small
            >
        </div>
        <div slot="footer">
            <unicom-linha :espacamento="false" :justificarItem="'end'">
                <unicom-coluna :md="3">
                    <unicom-botao class="fonte-secundaria texto" :bloco="true" @click="fecharModal()"
                        >FECHAR</unicom-botao
                    >
                </unicom-coluna>
            </unicom-linha>
            <unicom-modal-loading :value="loading" />
        </div>
    </unicom-modal>
</template>

<script>
import Papel from "../../../../../services/gerenciarAplicacoes/Papel";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        id: {
            type: [Number, String],
            required: false,
            note: "Id da aplicação selecionada"
        },
        papeis: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            mostrar: this.value,
            idAplicacao: this.id,
            listaPapeis: [],
            clonePapel: {},
            adminOnly: false,
            loading: false
        };
    },
    computed: {
        edicaoAberta() {
            return this.verificarEdicaoAberta() ? "inativa" : "";
        }
    },
    methods: {
        async patchPapel(idAplicacao, papel) {
            this.loading = true;
            let validacao = await this.$refs.observer.validate();

            if (validacao) {
                let papelPatch = new Papel(this.$appConfig.config.API);
                try {
                    await papelPatch.patchNovoPapel(idAplicacao, papel);
                    this.fecharEdicao();
                    // this.$emit("novo-papel");
                } catch (err) {
                    console.error(err);
                } finally {
                    this.loading = false;
                }
            }
        },
        async putPapel(idPapel, papel) {
            this.loading = true;
            let validacao = await this.$refs.observer.validate();

            if (validacao) {
                let papelPut = new Papel(this.$appConfig.config.API);
                try {
                    await papelPut.putEditarPapel(idPapel, papel);
                    this.fecharEdicao();
                    // this.$emit("novo-papel");
                } catch (err) {
                    console.error(err);
                } finally {
                    this.loading = false;
                }
            }
        },
        async deletePapel(idPapel, idAplicacao) {
            this.loading = true;
            let papelDelete = new Papel(this.$appConfig.config.API);
            try {
                await papelDelete.deletePapel(idPapel, idAplicacao);
                this.fecharEdicao();
                // this.$emit("novo-papel");
            } catch (err) {
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        linhaEdicao(papel) {
            return papel.edicao ? "linha-edicao" : "";
        },
        verificarEdicaoAberta() {
            let edicaoAberta = false;
            this.listaPapeis.forEach(papel => {
                if (papel.edicao) {
                    edicaoAberta = true;
                }
            });
            return edicaoAberta;
        },
        fecharEdicao() {
            this.listaPapeis.forEach((papel, index) => {
                if (papel.edicao) {
                    papel.edicao = false;
                }
                if (papel.id === null) {
                    this.cancelarEdicao(index);
                }
            });
        },
        adicionarPapelLista() {
            if (!this.verificarEdicaoAberta()) {
                this.listaPapeis.push({
                    id: null,
                    nome: "",
                    descricao: "",
                    edicao: true,
                    adminOnly: false
                });
            }
        },
        editarPapel(index) {
            if (!this.verificarEdicaoAberta()) {
                this.listaPapeis[index].edicao = true;
                this.adminOnly = this.listaPapeis[index].adminOnly;
            }
        },
        confirmar(index, papel) {
            let papelEditado = {
                id: papel.id,
                nome: papel.nome,
                descricao: papel.descricao,
                adminOnly: this.adminOnly
            };
            if (papelEditado.id != null) {
                this.putPapel(papelEditado.id, papelEditado);
            } else {
                this.patchPapel(this.idAplicacao, papelEditado);
            }
            this.listaPapeis[index].adminOnly = this.adminOnly;
            this.adminOnly = false;
        },
        excluirPapel(index) {
            if (!this.verificarEdicaoAberta() && this.listaPapeis.length > 1) {
                this.deletePapel(this.listaPapeis[index].id, this.idAplicacao);
                this.listaPapeis.splice(index, 1);
            }
        },
        cancelarEdicao(index) {
            this.adminOnly = false;
            this.listaPapeis[index].edicao = false;
        },
        apenasAdministrador(index) {
            return this.listaPapeis[index].adminOnly ? "lock" : "unlock";
        },
        atualizarApenasAdministrador() {
            this.adminOnly = !this.adminOnly;
        },
        fecharModal(valor) {
            if (!valor) {
                this.fecharEdicao();
                this.$emit("fechar");
            }
        },
        copiarIdPapel(papel) {
            const dado = papel.keyUUID;

            if (dado) {
                let inputCopiar = document.createElement("input");
                inputCopiar.value = dado;

                document.body.appendChild(inputCopiar);
                inputCopiar.select();

                document.execCommand("copy");
                document.body.removeChild(inputCopiar);
            }
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
            this.listaPapeis = this.papeis.map(o => ({ ...o }));

            if (!this.mostrar && this.verificarEdicaoAberta()) {
                this.fecharEdicao();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #00995d;
}

.container-corpo {
    .tabela-cabecalho {
        height: 40px;
        border-bottom: 1px solid #ebebeb;

        & .coluna {
            box-sizing: border-box;
            height: 100%;
            padding-top: 10px;
            padding-left: 11px;
            color: #60656c;

            & .centralizada {
                display: block;
                width: 50px;
                margin: 0 auto;
            }

            & .acao-centralizada {
                display: block;
                width: 40px;
                margin: 0 auto;
            }
        }
    }

    .tabela-linha {
        min-height: 40px;
        border-bottom: 1px solid #ebebeb;

        &.linha-edicao {
            border-bottom: 1px solid transparent;
        }

        & .coluna {
            box-sizing: border-box;
            height: 100%;
            color: #60656c;

            &.exibicao {
                margin: auto 0;
                padding: 10px 0px 10px 11px;
                // border: 1px solid red;
                // word-break: break-all;
            }

            & .centralizada {
                box-sizing: border-box;
                width: 50px;
                min-height: 40px;
                margin: 10px auto;
            }

            & .acao-centralizada {
                width: 120px;
                min-height: 40px;
                margin: 10px auto;
            }
        }

        & .campo-texto {
            margin-left: 11px;
            width: calc(100% - 16px);
        }

        & .icone-container {
            display: block;
            float: left;
            margin-top: 6px;
            margin-left: 11px;
            padding-left: 4px;
            padding-top: 3px;
            box-sizing: border-box;
            width: 26px;
            height: 26px;
            border: 2px solid #cccccc;
            color: #cccccc;
            border-radius: 20px;
            cursor: pointer;

            &.inativa {
                cursor: not-allowed;
            }
        }

        & .exclusao {
            &:hover {
                border-color: red;
                color: red;
            }
        }

        & .adicionar {
            &:hover {
                border-color: #00995d;
                color: #00995d;
            }
        }

        & .copiar {
            &:hover {
                border-color: #1492e6;
                color: #1492e6;
            }
        }

        & .admin-apenas {
            &:hover {
                border-color: #1492e6;
                color: #1492e6;
            }
        }
    }

    small {
        display: block;
        width: 150px;
        margin-top: 22px;
        color: #1492e6;
        cursor: pointer;

        &.inativa {
            color: #ccc;
            cursor: not-allowed;
        }
    }
}
</style>
