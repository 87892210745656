<template>
    <div>
        <unicom-modal
            v-model="mostrar"
            :largura="733"
            fechar
            class="modal-cadastro-aplicacao"
            @input="valor => fecharModal(valor)"
        >
            <div slot="header">
                <h2 class="fonte-primaria titulo negrito">CADASTRAR NOVA APLICAÇÃO</h2>
                <unicom-linha :espacamento="false" :justificarItem="'center'" class="linha-steps">
                    <unicom-coluna :md="1">
                        <button :class="`step ${stepAtivo(0)}`" @click="trocaStep(0)"></button>
                        <button :class="`step ${stepAtivo(1)} ${stepBloqueado}`" @click="trocaStep(1)"></button>
                    </unicom-coluna>
                </unicom-linha>
                <h3 class="fonte-primaria titulo negrito">{{ textoStep }}</h3>
            </div>
            <div slot="body" class="container-corpo">
                <ValidationObserver ref="primeiraEtapa" v-if="index === 0">
                    <template>
                        <unicom-linha :espacamento="true" class="linha-cadastro">
                            <unicom-coluna>
                                <validation-provider rules="required|nomeAplicacao" mode="eager" v-slot="{ errors }">
                                    <unicom-campo-texto
                                        v-model="novaAplicacao.nome"
                                        :titulo="'Nome da aplicação *'"
                                        :placeholder="'Insira o nome'"
                                        :maxCaracteres="'50'"
                                        apenasTextoNumero
                                        :mensagemErro="errors[0]"
                                    />
                                </validation-provider>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha :espacamento="true" class="linha-cadastro">
                            <unicom-coluna>
                                <unicom-campo-texto
                                    v-model="novaAplicacao.descricao"
                                    :titulo="'Descrição breve'"
                                    :placeholder="'Insira a descrição'"
                                    :maxCaracteres="'62'"
                                    localizacaoTooltip="top-left"
                                    tooltip='Será informada junto com o nome da aplicação em "Minhas aplicações".'
                                />
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha :espacamento="true" class="linha-cadastro">
                            <unicom-coluna>
                                <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                    <unicom-campo-texto
                                        v-model="novaAplicacao.url"
                                        :titulo="'URL da aplicação *'"
                                        :placeholder="'Insira a URL'"
                                        :maxCaracteres="'300'"
                                        :mensagemErro="errors[0]"
                                        removeAcentos
                                        tooltip="Principal URL de comunicação da aplicação."
                                    />
                                </validation-provider>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha :espacamento="true" class="linha-cadastro">
                            <unicom-coluna :sm="11" :md="5">
                                <unicom-switch
                                    :titulo="'Status da aplicação'"
                                    v-model="novaAplicacao.ativa"
                                    :color="'#000'"
                                    :disabled="false"
                                    :number="0"
                                    :textoVerdadeiro="'visível'"
                                    :textoFalso="'invisível'"
                                    @input="valor => (novaAplicacao.status = valor)"
                                    localizacaoTooltip="top-left"
                                    tooltip="Enquanto INATIVADA, a aplicação não será listada para usuários."
                                />
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha :espacamento="true">
                            <unicom-coluna :sm="11" :md="8" style="display: flex">
                                <unicom-checkbox
                                    v-model="comunicacao.limitarUsuarios"
                                    @input="trocaValorLimitar()"
                                    :texto="'Limitar quantidade de usuários por Unimed'"
                                />

                                <unicom-tooltip class="tooltip-status" tooltipLocalizacao="right-bottom">
                                    <div slot="itemTooltip">
                                        <unicom-icone nome="help-circle" :tamanho="18" />
                                    </div>

                                    <div slot="conteudo-tooltip">
                                        <p class="tooltip-content">
                                            Limite máximo de usuários por Unimed. Quando selecionado, esse limite irá
                                            valer para todas Unimeds com exceção da Unimed do Brasil.
                                        </p>
                                    </div>
                                </unicom-tooltip>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha :espacamento="true">
                            <unicom-coluna md="2">
                                <validation-provider :rules="validacaoLimitarUsuarios" mode="eager" v-slot="{ errors }">
                                    <unicom-campo-texto
                                        v-model="comunicacao.quantidadeUsuarios"
                                        :placeholder="'0'"
                                        :apenasNumero="true"
                                        v-if="comunicacao.limitarUsuarios"
                                        :mensagemErro="errors[0]"
                                    />
                                </validation-provider>
                            </unicom-coluna>
                        </unicom-linha>
                    </template>
                </ValidationObserver>
                <ValidationObserver ref="segundaEtapa" v-else>
                    <template>
                        <unicom-linha :espacamento="false">
                            <unicom-coluna>
                                <unicom-linha
                                    :espacamento="false"
                                    class="fonte-secundaria texto negrito tabela-cabecalho"
                                >
                                    <unicom-coluna :md="5" class="coluna">
                                        Nome do papel
                                    </unicom-coluna>
                                    <unicom-coluna :md="5" class="coluna">
                                        Descrição
                                    </unicom-coluna>
                                    <unicom-coluna :md="2" class="coluna">
                                        Ações
                                    </unicom-coluna>
                                </unicom-linha>
                                <unicom-linha
                                    :espacamento="false"
                                    class="fonte-secundaria texto negrito tabela-corpo"
                                    v-for="(papel, index) in novaAplicacao.papeisAplicacao"
                                    :key="index"
                                >
                                    <unicom-coluna :md="5" class="coluna corpo">
                                        <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                            <unicom-campo-texto
                                                v-model="papel.nome"
                                                :placeholder="'Insira o nome do papel *'"
                                                :maxCaracteres="'40'"
                                                apenasAlfaNumerico
                                                :mensagemErro="errors[0]"
                                            />
                                        </validation-provider>
                                    </unicom-coluna>
                                    <unicom-coluna :md="5" class="coluna corpo">
                                        <unicom-campo-texto
                                            v-model="papel.descricao"
                                            :placeholder="'Insira a descrição do papel'"
                                            :maxCaracteres="'62'"
                                            :apenasAlfaNumerico="true"
                                        />
                                    </unicom-coluna>
                                    <unicom-coluna :md="2" class="coluna corpo">
                                        <div
                                            :class="`icone ${verificarExclusao}`"
                                            @click="index > 0 ? excluirPapel(index) : ''"
                                        >
                                            <unicom-icone :nome="'x'" :tamanho="22" />
                                        </div>
                                        <div class="icone" @click="statusAdministrador(index)">
                                            <unicom-tooltip tooltipLocalizacao="top-right">
                                                <div slot="itemTooltip">
                                                    <unicom-icone
                                                        :nome="iconeApenasAdministrador(index)"
                                                        :tamanho="22"
                                                    />
                                                </div>
                                                <div slot="conteudo-tooltip">
                                                    <p>Quando trancado, apenas a Unimed do Brasil,</p>
                                                    <p>poderá atribuir usuários neste papel</p>
                                                </div>
                                            </unicom-tooltip>
                                        </div>
                                    </unicom-coluna>
                                </unicom-linha>
                                <unicom-linha :espacamento="false" class="tabela-rodape">
                                    <unicom-coluna :md="3">
                                        <small class="fonte-secundaria texto" @click="novoPapel()"
                                            >+ cadastrar novo papel</small
                                        >
                                    </unicom-coluna>
                                </unicom-linha>
                            </unicom-coluna>
                        </unicom-linha>
                    </template>
                </ValidationObserver>
            </div>
            <div slot="footer">
                <unicom-linha :espacamento="false" :justificarItem="'center'" class="linha-rodape">
                    <unicom-coluna :md="6">
                        <unicom-linha :espacamento="false" :justificarItem="'start'">
                            <unicom-coluna :md="6">
                                <unicom-botao
                                    :tipo="'primario'"
                                    :contornado="true"
                                    class="fonte-secundaria texto"
                                    @click="verificaObjetoIgual()"
                                >
                                    CANCELAR
                                </unicom-botao>
                            </unicom-coluna>
                        </unicom-linha>
                    </unicom-coluna>
                    <unicom-coluna :md="6">
                        <unicom-linha :espacamento="true" :justificarItem="'end'">
                            <unicom-coluna :md="6" v-if="index < 1">
                                <unicom-botao
                                    :tipo="'primario'"
                                    :desabilitado="habilitarProximoPasso"
                                    class="fonte-secundaria texto botao"
                                    @click="avancarEtapa()"
                                >
                                    CONTINUAR
                                </unicom-botao>
                            </unicom-coluna>
                            <unicom-coluna :md="4" v-if="index > 0">
                                <unicom-botao
                                    :tipo="'secundario'"
                                    class="fonte-secundaria texto botao"
                                    @click="index = index - 1"
                                >
                                    VOLTAR
                                </unicom-botao>
                            </unicom-coluna>
                            <unicom-coluna :md="8" v-if="index > 0">
                                <unicom-botao
                                    :tipo="'primario'"
                                    :desabilitado="habilitarCadastrar"
                                    :bloco="true"
                                    class="fonte-secundaria texto"
                                    @click="cadastrarAplicacao()"
                                >
                                    CADASTRAR APLICAÇÃO
                                </unicom-botao>
                            </unicom-coluna>
                        </unicom-linha>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-modal>

        <unicom-modal-confirmacao
            v-model="mostrarModalConfirmacao"
            textoBotaoCancelar="Cancelar agora"
            textoBotaoConfirmar="Continuar cadastrando"
            mensagem="Todas as configurações feitas até agora serão perdidas."
            @fechar="mostrarModalConfirmacao = false"
            @botao-cancelar="cancelar"
        />
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { isEqual, cloneDeep } from "lodash";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            mostrar: this.value,
            mostrarModalConfirmacao: false,
            index: 0,
            desativado: true,
            novaAplicacaoClone: {},
            novaAplicacao: {
                nome: "",
                descricao: "",
                url: "",
                ativa: true,
                papeisAplicacao: [
                    {
                        descricao: "",
                        nome: "",
                        adminOnly: false
                    }
                ],
                quantidadeUnimed: null
            },
            comunicacao: {
                limitarUsuarios: false,
                quantidadeUsuarios: ""
            },
            listaPapeis: [
                {
                    nome: "",
                    descricao: "",
                    apenasAdministrador: false
                }
            ]
        };
    },
    beforeMount() {
        this.novaAplicacaoClone = cloneDeep(this.novaAplicacao);
    },

    computed: {
        textoStep() {
            return this.index === 0 ? "Insira os dados básicos da aplicação" : "Agora, insira os papéis da aplicação";
        },
        habilitarProximoPasso() {
            return this.novaAplicacao.nome != "" && this.urlValida(this.novaAplicacao.url) ? false : true;
        },
        habilitarCadastrar() {
            return this.novaAplicacao.papeisAplicacao[0].nome != "" ? false : true;
        },
        stepBloqueado() {
            return this.novaAplicacao.nome != "" && this.urlValida(this.novaAplicacao.url) ? "" : "inativo";
        },
        verificarExclusao() {
            return this.novaAplicacao.papeisAplicacao.length < 2 ? "inativo" : "";
        },
        validacaoLimitarUsuarios() {
            return this.comunicacao.limitarUsuarios ? "required|numeric|limitadorValidate" : "";
        }
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
                this.limparModal();
            }
        },
        stepAtivo(index) {
            return this.index === index ? "ativo" : "";
        },

        urlValida(valor) {
            // const regra = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            // eslint-disable-next-line
            const regra = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            return regra.test(valor);
        },
        async trocaStep(index) {
            let validacaoStep = await this.$refs.primeiraEtapa.validate();
            if (validacaoStep && this.novaAplicacao.nome != "" && this.novaAplicacao.url != "") {
                this.index = index;
            }
        },
        async avancarEtapa() {
            let primeiraValidacao = await this.$refs.primeiraEtapa.validate();
            if (primeiraValidacao) {
                this.index = this.index + 1;
            }
        },
        iconeApenasAdministrador(index) {
            return this.novaAplicacao.papeisAplicacao[index].adminOnly ? "lock" : "unlock";
        },
        limparModal() {
            this.novaAplicacao = {
                nome: "",
                descricao: "",
                url: "",
                ativa: true,
                papeisAplicacao: [
                    {
                        descricao: "",
                        nome: ""
                    }
                ]
            };
            this.comunicacao.quantidadeUsuarios = "";
        },
        cancelar() {
            this.limparModal();
            this.index = 0;
            this.mostrar = false;
        },
        verificaObjetoIgual() {
            if (isEqual(this.novaAplicacaoClone, this.novaAplicacao)) {
                this.cancelar();
            } else {
                this.mostrarModalConfirmacao = true;
            }
        },
        novoPapel() {
            this.novaAplicacao.papeisAplicacao.push({
                nome: "",
                descricao: "",
                adminOnly: false
            });
        },
        excluirPapel(index) {
            this.novaAplicacao.papeisAplicacao.splice(index, 1);
        },
        statusAdministrador(index) {
            this.novaAplicacao.papeisAplicacao[index].adminOnly = !this.novaAplicacao.papeisAplicacao[index].adminOnly;
        },
        async cadastrarAplicacao() {
            let segundaValidacao = await this.$refs.segundaEtapa.validate();
            if (segundaValidacao) {
                this.novaAplicacao.quantidadeUnimed =
                    this.comunicacao.quantidadeUsuarios > 0 ? this.comunicacao.quantidadeUsuarios : null;
                this.$emit("adicionar", this.novaAplicacao);
                this.cancelar();
                this.fecharModal();
            }
        },
        trocaValorLimitar() {
            this.comunicacao.quantidadeUsuarios = "";
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.tooltip-status {
    flex-grow: 1;
    color: #9e9e9e;
    padding-top: 5px;
    cursor: pointer;

    &:hover {
        color: #00995d;
    }
}

.tooltip-content {
    width: 300px;
    height: 65px;
    white-space: pre-line;
}

.modal-cadastro-aplicacao {
    & h2 {
        text-align: center;
        color: #00995d;
    }

    & h3 {
        text-align: center;
        color: #60656c;
    }

    & .linha-steps {
        margin: 24px 0;

        & .step {
            float: left;
            box-sizing: border-box;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #cccccc;
            cursor: pointer;

            &:first-child {
                margin-right: 16px;
            }

            &.ativo {
                background-color: #00995d;
            }

            &.inativo {
                background-color: #cccccc;
                cursor: not-allowed;
            }
        }
    }

    & .container-corpo {
        min-height: 320px;

        & .linha-cadastro {
            margin-top: 31px;

            &:first-child {
                margin-top: 24px;
            }
        }

        & .coluna {
            padding: 8px;

            &.corpo {
                box-sizing: border-box;
                margin-top: 10px;
                height: 50px;
                padding-top: 0;

                & .icone {
                    float: left;
                    margin: 6px 12px 4px 0;
                    box-sizing: border-box;
                    width: 26px;
                    height: 26px;
                    border: 2px solid #cccccc;
                    border-radius: 20px;
                    color: #cccccc;
                    cursor: pointer;

                    &:last-child {
                        border-color: transparent;
                    }

                    &.inativo {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    & .tabela-rodape {
        color: #1492e6;
        margin-top: 10px;

        & small {
            display: block;
            float: left;
            cursor: pointer;
        }
    }

    & .linha-rodape {
        margin-top: 10px;

        .botao {
            float: right;
        }
    }
}
</style>
